import React, { useEffect, useState } from 'react';

import NotFound from '@modules/not-found/layout';

export default function NotFoundPage() : React.ReactElement {
  const [language, setLanguage] = useState(process.env.GATSBY_DEFAULT_LANG || 'en');

  useEffect(() => {
    if (navigator && navigator.language) {
      setLanguage(navigator.language.split('-')[0]);
    }
  }, []);

  return (
    <NotFound language={language} />
  );
}
